import React from 'react';
import Portal from '../Portal';
import { useState } from 'react';
import {
	MobileNavWrapper,
	MobileNavOverlay,
	MobileNavContainer,
	CloseButton,
	MobileNavContentWrapper,
	MobileHeaderAuthLink,
} from './MobileMainNav.dump';
import { ReactComponent as BurgerRed } from 'src/styles/assets/images/ui/icons/icon-menu-red.svg';
import { ReactComponent as BurgerWhite } from 'src/styles/assets/images/ui/icons/icon-menu-white.svg';
import { ReactComponent as CloseIcon } from 'src/styles/assets/images/ui/icons/icon-close-red.svg';

export interface MobileMainNavProps extends React.HTMLAttributes<HTMLDivElement> {
	theme?: string;
}

const MobileMainNav: React.FC<MobileMainNavProps> = ({ theme, children }) => {
	const [isOpen, setOpen] = useState(false);
	const BurgerIcon = theme === 'white' ? BurgerRed : BurgerWhite;

	return (
		<MobileNavWrapper>
			<button onClick={() => setOpen(!isOpen)}>
				<BurgerIcon />
			</button>
			<Portal>
				<MobileNavOverlay isOpen={isOpen} />
				<MobileNavContainer isOpen={isOpen}>
					<MobileNavContentWrapper>
						<CloseButton onClick={() => setOpen(!isOpen)}>
							<CloseIcon />
						</CloseButton>
						{children}
						<MobileHeaderAuthLink href="https://dashboard.virgilsecurity.com/">
							Login
						</MobileHeaderAuthLink>
						<MobileHeaderAuthLink
							className="red"
							href="https://dashboard.virgilsecurity.com/signup"
						>
							Get Started
						</MobileHeaderAuthLink>
					</MobileNavContentWrapper>
				</MobileNavContainer>
			</Portal>
		</MobileNavWrapper>
	);
};

export default MobileMainNav;
