import React, { ReactNode, useEffect, useState } from 'react';
import {
	NavigationContent,
	NavigationLink,
	NavigationSecondList,
	NavigationSecondLink,
	NavigationSecondTitle,
	NavigationSecondDescription,
	NavigationSecondText,
	NavigationSecondListContainer,
	NavigationSecondListTitle,
} from './Navigation.dump';
import navigation from '../../../content/Navigation.yml';
import { MenuItem } from 'src/types';
import { globalHistory } from '@reach/router/lib/history';
import classnames from 'classnames';

const Navigation: React.FC = () => {
	const getFirstLevelItem = (item: MenuItem): ReactNode => {
		// This is a temporary hot-fix, while pricing is static page
		const [link, setLink] = useState<string | undefined>(item.link);
		useEffect(() => {
			if (item.link === '/pricing/') {
				setLink(globalHistory.location.origin + item.link);
			}
		}, []);
		//
		const isActive = globalHistory.location.pathname === link;
		return (
			<NavigationLink className={classnames({ active: isActive })} key={item.title} href={link}>
				{item.title}
			</NavigationLink>
		);
	};

	const getSecondLevelItem = (item: MenuItem): ReactNode => {
		const isActive = globalHistory.location.pathname === item.link;
		return (
			<NavigationSecondLink
				className={classnames({ active: isActive })}
				href={item.link}
				key={item.title}
			>
				{item.logo && <img src={item.logo} alt={item.title + ' Logo'} />}
				<NavigationSecondText>
					<NavigationSecondTitle>{item.title}</NavigationSecondTitle>
					<NavigationSecondDescription>{item.description}</NavigationSecondDescription>
				</NavigationSecondText>
			</NavigationSecondLink>
		);
	};

	const getSecondLevelMenu = (item: MenuItem): ReactNode => {
		const isActive = item.readyToIntegrate
			? item.readyToIntegrate.some(it => it.link === globalHistory.location.pathname)
			: false;
		return (
			<NavigationLink className={classnames({ active: isActive })} key={item.title}>
				<p>{item.title}</p>
				<NavigationSecondList>
					{item.readyToIntegrate && (
						<NavigationSecondListContainer>
							<NavigationSecondListTitle>Ready to integrate</NavigationSecondListTitle>
							{item.readyToIntegrate.map(second => getSecondLevelItem(second))}
						</NavigationSecondListContainer>
					)}
					{item.readyToRun && (
						<NavigationSecondListContainer>
							<NavigationSecondListTitle>Ready to run</NavigationSecondListTitle>
							{item.readyToRun.map(second => getSecondLevelItem(second))}
						</NavigationSecondListContainer>
					)}
				</NavigationSecondList>
			</NavigationLink>
		);
	};

	const getItem = (item: MenuItem): ReactNode => {
		if (!item.readyToIntegrate) return getFirstLevelItem(item);
		return getSecondLevelMenu(item);
	};

	return <NavigationContent>{navigation.map(item => getItem(item))}</NavigationContent>;
};

export default Navigation;
