import React from 'react';
import logo from '../../../styles/assets/images/shared/logo-white.svg';

const Footer: React.FC = () => {
	return (
		<footer className="footer">
			<div className="footer-top">
				<div className="wrapper">
					<div className="footerContacts">
						<div className="footerContacts-logo">
							<a href="">
								<img src={logo} width="145" height="47" alt="" />
							</a>
						</div>
						<div className="footerContacts-hashtag">#SecuredByVirgil</div>
						<ul className="footerContacts-socials">
							<li>
								<a href="https://www.facebook.com/VirgilSec">
									<i className="icon icon-facebook"></i>
								</a>
							</li>
							<li>
								<a href="https://github.com/VirgilSecurity">
									<i className="icon icon-github"></i>
								</a>
							</li>
							<li>
								<a href="https://twitter.com/VirgilSecurity">
									<i className="icon icon-twitter"></i>
								</a>
							</li>
							<li>
								<a href="https://www.linkedin.com/company/Virgil-Security-inc-">
									<i className="icon icon-linkedin"></i>
								</a>
							</li>
							<li>
								<a href="https://medium.com/@VirgilSecurity">
									<i className="icon icon-medium"></i>
								</a>
							</li>
						</ul>
					</div>
					<nav className="footerNav">
						<div className="footerNavBlock footerNavBlock-sources">
							<div className="footerNavBlock-caption">Navigation</div>
							<ul>
								<li>
									<a href="/">Home</a>
								</li>
								<li>
									<a href="/pricing/">Pricing</a>
								</li>
								<li>
									<a href="/terms-of-service/">Legal</a>
								</li>
							</ul>
						</div>
						<div className="footerNavBlock">
							<div className="footerNavBlock-caption">Developers</div>
							<ul>
								<li>
									<a href="https://developer.virgilsecurity.com">Documentation</a>
								</li>
							</ul>
						</div>
						<div className="footerNavBlock">
							<div className="footerNavBlock-caption">Contact us</div>
							<ul>
								<li>
									<a href="/contact/">Contacts</a>
								</li>
								<li>
									<a href="/blog/">Blog</a>
								</li>
							</ul>
						</div>
					</nav>
				</div>
			</div>
			<div className="footer-bottom">
				<div className="wrapper">
					<div className="footer-copyright">&copy; 2014 - 2021 Virgil Security, Inc.</div>
					<div className="footer-links">
						<a target="_top" href="mailto:support@virgilsecurity.com" className="footer-email">
							Support@VirgilSecurity.com
						</a>
						<a href="/join-community/" className="footer-policy">
							Need help? Join Our Slack channel
						</a>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
