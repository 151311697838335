import React, { useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import { canUseDOM } from '../../utils/domHelpers';

const ScrollToTop: React.FC = () => {
	const ref = useRef<HTMLDivElement>(null);
	const [isVisible, setVisibility] = useState(false);
	const className = classnames('scrollToTop', isVisible && 'pinned');

	const scroll = () => {
		if (!canUseDOM()) return;
		window.scrollTo({ top: 0, behavior: 'smooth' });
	};

	useEffect(() => {
		const toggle = () => {
			const startPosition = window.innerHeight;
			const currentPosition = window.scrollY;
			if (ref.current) {
				currentPosition >= startPosition ? setVisibility(true) : setVisibility(false);
			}
		};
		window.addEventListener('scroll', toggle);
	}, []);

	return (
		<div className={className} ref={ref} data-vs-sticky-scroll-to-top>
			<button onClick={scroll} className="scrollToTop-btn">
				<i className="icon icon-arrowTop-white"></i>
			</button>
		</div>
	);
};

export default ScrollToTop;
