import styled from 'styled-components';
import { ButtonContainer } from '../buttons/Button.dump';
import { WIDTH_BREAKPOINT_MD } from 'src/constants';

export const HeaderContent = styled.div`
	align-items: center;
	display: flex;
	height: 70px;
	justify-content: space-between;
	left: 0;
	margin-bottom: -70px;
	position: relative;
	top: 0;
	width: 100%;
	z-index: 10;
`;

export const HeaderNavWrapper = styled.div`
	display: none;

	@media (min-width: ${WIDTH_BREAKPOINT_MD}) {
		display: flex;
	}
`;

export const HeaderAuth = styled.div`
	display: none;

	@media (min-width: ${WIDTH_BREAKPOINT_MD}) {
		display: block;
	}
`;

export const HeaderLink = styled(ButtonContainer)`
	color: ${props => (props.theme.header === 'white' ? '#333' : '#fff')};
	cursor: pointer;
	display: inline-block;
	font-family: 'Muller', sans-serif;
	font-size: 0.77em;
	letter-spacing: 1px;
	line-height: 1.3;
	text-transform: uppercase;
	transition: all 0.5s;

	&:hover,
	.active {
		color: ${props => (props.theme.header === 'white' ? '#9e3621' : '#fff')};
	}
`;

export const HeaderBorderLink = styled(HeaderLink)`
	border: 2px solid ${props => (props.theme.header === 'white' ? '#bb241d' : '#fff')};
	border-radius: 3px;
	color: ${props => (props.theme.header === 'white' ? '#bb241d' : '#fff')};
	height: 42px;
	line-height: 42px;
	margin-left: 30px;
	padding: 0 30px;

	&:hover {
		background: #bb241d;
		border-color: transparent;
		box-shadow: 0 18px 20px -15px rgba(158, 54, 33, 0.5);
		color: #ffffff;
	}
`;
