import React from 'react';
import SeoWrapper from '../components/seo/SeoWrapper';
import Header from '../components/Header/Header';
import Footer from '../components/sections/footer/Footer';
import ScrollToTop from '../components/layout/ScrollToTop';
import { SeoPageProps } from 'src/types';
import classnames from 'classnames';

export interface LayoutProps extends React.HTMLAttributes<HTMLDivElement> {
	seoPage: SeoPageProps;
	theme?: string;
}

const Layout: React.FC<LayoutProps> = ({ seoPage, children, className, theme, ...props }) => {
	return (
		<SeoWrapper
			title={seoPage.tab_title}
			description={seoPage.meta_description}
			keywords={seoPage.meta_keywords}
			article={seoPage.article}
		>
			<div className={classnames('page', className)} {...props}>
				<Header theme={theme} />
				<div className="container">{children}</div>
				<Footer />
				<ScrollToTop />
			</div>
		</SeoWrapper>
	);
};

export default Layout;
