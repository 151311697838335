import styled from 'styled-components';
import { WIDTH_BREAKPOINT_MD } from 'src/constants';
import { NavigationLink } from './Navigation.dump';

export const MobileNavWrapper = styled.div`
	display: flex;

	@media (min-width: ${WIDTH_BREAKPOINT_MD}) {
		display: none;
	}
`;

export const MobileNavOverlay = styled.div<{ isOpen: boolean }>`
	background: #000000;
	bottom: 0;
	height: ${props => (props.isOpen ? '100%' : '0')};
	left: 0;
	opacity: ${props => (props.isOpen ? '0.3' : '0')};
	pointer-events: none;
	position: fixed;
	right: 0;
	top: 0;
	transition: visibility 0.5s, opacity 0.5s;
	visibility: ${props => (props.isOpen ? 'initial' : 'hidden')};
	z-index: 20;
`;

export const MobileNavContainer = styled.div<{ isOpen: boolean }>`
	background: #eceff1;
	height: ${props => (props.isOpen ? '100%' : '0')};
	opacity: ${props => (props.isOpen ? '1' : '0')};
	overflow-y: scroll;
	position: fixed;
	right: 0;
	top: 0;
	transition: visibility 0.5s, opacity 0.5s;
	visibility: ${props => (props.isOpen ? 'initial' : 'hidden')};
	width: 100%;
	z-index: 30;
`;

export const MobileNavContentWrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

export const MobileHeaderAuthLink = styled(NavigationLink)`
	background: #fff2f2;
	border-radius: 0 0 3px 3px;
	color: #bb241d;
	cursor: pointer;
	display: block;
	font-family: Muller, sans-serif;
	font-size: 0.77em;
	letter-spacing: 1px;
	line-height: 1.3;
	padding: 30px;
	text-transform: uppercase;
	width: 100%;

	&.red {
		background: #9e3621;
		color: white;
	}
`;

export const CloseButton = styled.button`
	right: 29px;
	top: 29px;
	position: absolute;
`;
