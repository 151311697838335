import styled from 'styled-components';
import { WIDTH_BREAKPOINT_MD, WIDTH_BREAKPOINT_SM } from 'src/constants';
import { HeaderLink } from './Header.dump';
import { ButtonContainer } from '../buttons/Button.dump';

export const NavigationContent = styled.nav`
	@media (max-width: ${WIDTH_BREAKPOINT_MD}) {
		display: flex;
		flex-direction: column;
	}
`;

export const NavigationLink = styled(HeaderLink)`
	@media (min-width: ${WIDTH_BREAKPOINT_MD}) {
		margin: 0 15px;
		border-top: 3px solid transparent;
		height: 67px;
		position: relative;
		line-height: 67px;

		&:hover,
		&.active {
			border-color: ${props => (props.theme.header === 'white' ? '#9e3621' : '#fff')};
		}
	}

	@media (max-width: ${WIDTH_BREAKPOINT_MD}) {
		color: #333333;
		letter-spacing: 0.45px;
		font-weight: bold;
		font-size: 18px;
		border-bottom: 1px solid white;
		border-top: 1px solid white;

		:not(div) {
			padding: 25px 20px;
		}

		:hover,
		&.active {
			color: #6f828c;
		}

		:not(a) {
			p {
				padding: 25px 20px;
			}
		}
	}
`;

export const NavigationSecondList = styled.ul`
	background: #ffffff;
	position: relative;
	display: flex;

	&::before {
		border: 10px solid transparent;
		border-bottom-color: #ffffff;
		border-bottom-width: 10px;
		content: '';
		height: 0;
		left: 13%;
		position: absolute;
		top: -19px;
		transform: translate(-15%);
		width: 0;

		@media (max-width: ${WIDTH_BREAKPOINT_MD}) {
			left: 9%;
		}

		@media (max-width: ${WIDTH_BREAKPOINT_SM}) {
			left: 18%;
		}
	}

	@media (min-width: ${WIDTH_BREAKPOINT_MD}) {
		border-radius: 10px;
		box-shadow: 0 15px 25px -10px rgba(0, 0, 0, 0.3);
		left: 10%;
		pointer-events: none;
		opacity: 0;
		position: absolute;
		top: 100%;
		transform: translate(-10%);
		transition: all 0.3s;

		${NavigationLink}:hover & {
			opacity: 1;
			pointer-events: initial;
		}
	}

	@media (max-width: ${WIDTH_BREAKPOINT_MD}) {
		padding: 10px 0 0;
		justify-content: space-between;
	}

	@media (max-width: ${WIDTH_BREAKPOINT_SM}) {
		flex-direction: column;
	}
`;

export const NavigationSecondListContainer = styled.div`
	margin: 25px 0 20px;
	flex: 1;
	padding: 0 10px;

	@media (max-width: ${WIDTH_BREAKPOINT_MD}) {
		padding: 0 20px;
	}

	@media (min-width: ${WIDTH_BREAKPOINT_SM}) {
		display: flex;
		flex-direction: column;
		width: fit-content;

		:first-child {
			border-right: 1px solid #eceff1;
		}
	}

	@media (min-width: ${WIDTH_BREAKPOINT_MD}) {
		min-width: 380px;
	}
`;

export const NavigationSecondListTitle = styled.span`
	font-size: 16px;
	font-weight: bold;
	line-height: 20px;
	color: #333333;
	margin: 0 20px 10px;
	text-transform: initial;
	letter-spacing: 0.16px;
	margin-bottom: 10px;
	display: block;

	@media (max-width: ${WIDTH_BREAKPOINT_MD}) {
		display: block;
		margin: 0;
		margin-bottom: 10px;
	}
`;

export const NavigationSecondLink = styled(ButtonContainer)`
	display: flex;
	font-size: 14px;
	font-weight: 500;
	line-height: 17px;
	letter-spacing: 0.35px;
	padding: 15px 20px;
	padding-right: 30px;
	border-radius: 5px;
	align-items: end;
	transition: background 0.2s;

	img {
		margin-right: 20px;

		@media (max-width: ${WIDTH_BREAKPOINT_SM}) {
			align-self: center;
			height: 100%;
			margin-right: 15px;
			min-width: 64px;
			max-height: 48px;
		}
	}

	:hover,
	&.active {
		background: #eceff1;
	}

	@media (max-width: ${WIDTH_BREAKPOINT_MD}) {
		padding: 15px 10px;
		margin: 0 -10px;
	}
`;

export const NavigationSecondText = styled.div`
	align-self: center;
	display: flex;
	flex-direction: column;
`;

export const NavigationSecondTitle = styled.span`
	color: #9e3621;
`;

export const NavigationSecondDescription = styled.span`
	font-size: 12px;
	line-height: 16px;
	font-weight: 300;
	font-family: 'Roboto', sans-serif;
	letter-spacing: 0.12px;
	color: #333333;
	text-transform: initial;

	@media (min-width: ${WIDTH_BREAKPOINT_MD}) {
		white-space: pre-line;
	}
`;
