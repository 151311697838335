import React from 'react';
import ReactDOM from 'react-dom';
import { canUseDOM } from '../utils/domHelpers';

const Portal: React.FC = props => {
	if (!canUseDOM()) return null;
	const { children } = props;
	return ReactDOM.createPortal(children, document.body);
};

export default Portal;
