import styled from 'styled-components';
import React from 'react';
import { Link } from 'gatsby';

export const ButtonContainer: React.FC<React.LinkHTMLAttributes<
	HTMLAnchorElement & HTMLDivElement
>> = ({ href, ...props }) => {
	if (href && href[0] === '/') {
		return <Link to={href} {...props} />;
	} else if (href) {
		return <a href={href} {...props} />;
	}
	return <div {...props} />;
};

export const OldRedButton = styled(ButtonContainer)`
	background: #bb241d;
	border: 0;
	border-radius: 3px;
	box-shadow: 0 15px 20px -15px rgba(158, 54, 33, 0.5);
	color: white;
	cursor: pointer;
	display: inline-block;
	font-family: 'Muller', sans-serif;
	font-size: 0.77rem;
	letter-spacing: 1px;
	padding: 12px 30px;
	text-transform: uppercase;
	transition: all 0.3s;

	&:hover {
		background-color: #da322c;
		box-shadow: 0 18px 20px -15px rgba(158, 54, 33, 0.5);
	}

	&:active {
		background-color: rgb(208, 64, 53);
	}
`;
