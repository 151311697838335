import React from 'react';
import { ReactComponent as LogoWhite } from 'src/styles/assets/images/shared/logo-white.svg';
import { ReactComponent as LogoRed } from 'src/styles/assets/images/shared/logo-red.svg';
import MobileMainNav from 'src/components/Header/MobileMainNav';
import {
	HeaderContent,
	HeaderNavWrapper,
	HeaderAuth,
	HeaderLink,
	HeaderBorderLink,
} from './Header.dump';
import { ThemeProvider } from 'styled-components';
import Navigation from './Navigation';

export interface HeaderProps extends React.HTMLAttributes<HTMLHeadingElement> {
	theme?: string;
}

const Header: React.FC<HeaderProps> = ({ theme }) => {
	const Logo = theme === 'white' ? LogoRed : LogoWhite;
	const NavTheme = {
		header: theme,
	};

	return (
		<header>
			<div className="wrapper">
				<ThemeProvider theme={NavTheme}>
					<HeaderContent>
						<HeaderLink href="/">
							<Logo />
						</HeaderLink>
						<HeaderNavWrapper>
							<Navigation />
						</HeaderNavWrapper>
						<HeaderAuth>
							<HeaderLink href="https://dashboard.virgilsecurity.com/">Login</HeaderLink>
							<HeaderBorderLink href="https://dashboard.virgilsecurity.com/signup">
								Get started
							</HeaderBorderLink>
						</HeaderAuth>
						<MobileMainNav theme={theme}>
							<Navigation />
						</MobileMainNav>
					</HeaderContent>
				</ThemeProvider>
			</div>
		</header>
	);
};

export default Header;
