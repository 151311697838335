import React from 'react';
import Helmet from 'react-helmet';
import { ArticleSeo } from 'src/types';

export interface SchemaOrgProps {
	author: string;
	defaultTitle: string;
	description: string;
	image: string;
	isBlogPost: boolean;
	organization: {
		url: string;
		logo: {
			url: string;
			width: number;
			height: number;
		};
		name: string;
	};
	title: string;
	keywords: string;
	url: string;
	article?: ArticleSeo;
}

const SchemaOrg: React.FC<SchemaOrgProps> = React.memo(
	({
		author,
		defaultTitle,
		description,
		image,
		isBlogPost,
		organization,
		title,
		keywords,
		url,
		article,
	}) => {
		const baseSchema = [
			{
				'@context': 'http://schema.org',
				'@type': 'WebSite',
				name: title,
				alternateName: defaultTitle,
			},
		];

		const schema =
			isBlogPost && article
				? [
						...baseSchema,
						{
							'@context': 'https://schema.org',
							'@type': 'TechArticle',
							headline: article.headline,
							image,
							author,
							keywords,
							url,
							description,
							wordcount: article.wordcount,
							datePublished: article.date,
							dateModified: article.mdate,
							dateCreated: article.date,
							publisher: {
								'@type': 'Organization',
								name: organization.name,
								url: organization.url,
								logo: {
									'@type': 'ImageObject',
									...organization.logo,
								},
							},
						},
				  ]
				: baseSchema;

		return (
			<Helmet>
				{/* Schema.org tags */}
				<script type="application/ld+json">{JSON.stringify(schema)}</script>
			</Helmet>
		);
	},
);

export default SchemaOrg;
