import React from 'react';
import Helmet from 'react-helmet';
import { globalHistory } from '@reach/router';
import siteMeta from '../../../content/seo/site-meta.yml';
import SchemaOrg from './SchemaOrg';
import { ArticleSeo } from 'src/types';

export interface SeoWrapperProps {
	description: string;
	title: string;
	keywords: string[];
	article?: ArticleSeo;
}

const SeoWrapper: React.FC<SeoWrapperProps> = ({
	description,
	title,
	keywords,
	article,
	children,
}) => {
	const url = globalHistory.location.href;
	const canonicalUrl = globalHistory.location.origin || siteMeta.url;
	const image = article ? `${canonicalUrl}${article.banner}` : siteMeta.img;
	const datePublished = article ? new Date(article.date).toISOString() : undefined;
	const author = article ? article.author : siteMeta.organization.name;
	return (
		<React.Fragment>
			<Helmet>
				{/* General tags */}
				<title>{title}</title>
				<meta name="description" content={description} />
				<meta name="keywords" content={keywords.join(', ')} />
				<link rel="canonical" href={url} />
				<meta property="title" content={title} />
				<meta name="image" content={image} />
				{/* OpenGraph tags */}
				<meta property="og:url" content={url} />
				{article ? <meta property="og:type" content="article" /> : null}
				<meta property="og:title" content={title} />
				<meta property="og:description" content={description} />
				<meta property="og:image" content={image} />
				<meta property="og:locale" content="en_US" />
				<meta property="og:type" content="website" />
				<meta property="og:site_name" content="Virgil Security" />
				<meta property="fb:app_id" content={siteMeta.social.fbAppID} />
				{/* Twitter Card tags */}
				<meta name="twitter:card" content="summary_large_image" />
				<meta name="twitter:creator" content={siteMeta.social.twitter} />
				<meta name="twitter:site" content={siteMeta.social.twitter} />
				<meta name="twitter:title" content={title} />
				<meta name="twitter:description" content={description} />
				<meta name="twitter:image" content={image} />
			</Helmet>
			{article && (
				<Helmet>
					<meta property="article:published_time" content={datePublished} />
					<meta property="al:web:url" content={globalHistory.location.href} />
					<meta property="article:author" content={author} />
					<meta name="author" content={author} />
				</Helmet>
			)}
			<SchemaOrg
				isBlogPost={article != undefined}
				url={url}
				title={title}
				image={image}
				description={description}
				author={author}
				organization={siteMeta.organization}
				defaultTitle={siteMeta.name}
				keywords={keywords.join(' ')}
				article={article}
			/>
			{children}
		</React.Fragment>
	);
};

export default SeoWrapper;
